<template>
  <v-list-item
      link
      :title="$t('menus.planeteOnlineNews')"
      :to="{ name: 'PlaneteOnlineNews' }"
      color="white"
  >
    <v-list-item-icon class="d-flex justify-center">
      <planete-icon color="white">planete-online</planete-icon>
      <v-badge
          v-if="hasNewNews"
          :content="hasNewNews"
          color="warning"
          left
          class="elevation-4"
          overlap
      ></v-badge>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">
        {{ $t("menus.planeteOnlineNews") }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Vuex from "vuex"

export default {
  name: "MenuNews",
  computed: {
    ...Vuex.mapState(["communication", "modules"]),
    hasNewNews() {
      let seens = this.$store.getters.getWidgetDatas(
          "planete-online-news",
          "seen"
      );
      return this.getRealNews.filter((a) => seens.indexOf(a._id) == -1).length;
    },
    getRealNews() {
      return this.communication.news.slice().filter((com) => {
        let date = com.date;
        if (date && !(date instanceof Date)) {
          date = new Date(date);
        }
        let expiration = null;
        if (com.expiration) {
          expiration = new Date(com.expiration);
        }

        let rights;
        if (com.module.length) {
          let allSubModules = [];
          this.modules.forEach((mod) => {
            mod.subModules
                .map((a) => a.path)
                .forEach((submod) => allSubModules.push(submod));
          });
          rights = com.module.some(
              (
                  comMod // Hé attention, je suis pas comMod
              ) => allSubModules.indexOf(comMod.path) !== -1
          );
        } else {
          rights = true;
        }

        return (
            date &&
            new Date() >= date &&
            (!com.module || rights) &&
            (!expiration || expiration > new Date())
        );
        // Retourne true si :
        // Il y a une date et qu'elle est dans le passé
        // &&
        // Qu'il n'y a aucun module de lié (donc com globale) ou qu'il y a un/plusieurs modules dont il a les droits
      });
    },
  },
}
</script>

<style scoped>

</style>